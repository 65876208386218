/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ApplicationStatusCard from './ApplicationStatusCard';
import BlogPostCard from './BlogPostCard';
import LoanIncompleteCard from './LoanIncompleteCard';
// import LoanPendingCard from "./LoanPendingCard";
import SeeMoreTitle from './SeeMoreTitle';
// import TrainingPreviewCard from "./TrainingPreviewCard";
import TrainingProgramCard from './TrainingProgramCard';
import { useQuery } from 'react-query';
import { getUserProfile } from 'src/api/User/User';
import MainButton from '@components/buttons/Buttons';
import training_thumb from '@images/bg/jas_hero_5.jpg';
import training_thumb_1 from '@images/bg/jas_hero_6.jpg';
import { useMediaQuery } from 'react-responsive';
import MobileDashboard from '@components/Mobile/Views/MobileDashboard';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { defaultBlue } from '@/utils/constants/colors.constants';
import constant from '@/utils/constants/constants';

export default function Home() {
  const user = JSON.parse(window.localStorage.getItem('user'));
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const {
    error,
    data: responseData,
    isLoading,
  } = useQuery('user-data', getUserProfile);

  const data = responseData?.data;

  const is_application_complete = data?.profile?.is_application_complete;

  function assignRedirect(data) {
    if (data && data?.onboarding_data) {
      const loan = data.onboarding_data;

      const allStages = [
        'personal_information',
        'education',
        'training',
        'accumen',
        'financial_literacy',
        'other_information',
        'application_terms',
        'completed',
      ];

      for (let stage of allStages) {
        const stageCompleted = loan[stage];

        if (!stageCompleted) {
          setRedirectUrl(`/onboarding/${stage}`);

          break;
        }
      }
    } else {
      setRedirectUrl(`/onboarding/personal_information`);
    }
  }

  const showGrantFormButton = () => {
    let grantStage = false;

    if (data) {
      if (data?.profile?.is_business_plan_application_complete) {
        return false;
      }
      data?.approvals?.forEach((approval) => {
        if (approval?.approval_type == 'advanced_training_approval') {
          grantStage = true;
        }
      });
    }
    return (grantStage && constant.SHOW_BUSINESS_FORM) == true;
  };

  // const showBusinessPlanFormButton = () => {
  //   let showBusinessPlan = false;
  //   if (data?.profile?.is_grant_application_complete == true) {
  //     showBusinessPlan = true;
  //   }
  //   return showBusinessPlan;
  // };

  useEffect(() => assignRedirect(data), [assignRedirect]);

  if (isLoading)
    return (
      <Container className="p-4">
        <h2 className="mt-4 me-4">Loading...</h2>;
      </Container>
    );

  if (error) return 'An error has occurred: ' + error.message;

  const statusComponent = (_status) => {
    let status = '';
    let style = {
      fontSize: '0.7rem',
      backgroundColor: '#ED1C2420',
      color: '#ED1C24',
      padding: '0.3rem',
      borderRadius: '0.3rem',
      fontWeight: 'bold',
      width: 'fit-content',
    };

    switch (_status) {
      case 'pending_application_approval':
        status = 'Pending Approval';
        style.backgroundColor = '#f9ffb8';
        style.color = '#838c01';
        break;
      case 'approved_application_approval':
        status = 'Approved for Interview';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_basic_training':
        status = 'Approved for Basic Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_intermediate_training':
        status = 'Approved for Intermediate Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_advanced_training':
        status = 'Approved for Advanced Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant':
        status = 'Approved for Grant';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant_pre_disbursement':
        status = 'Approved for Pre Disbursement';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant_post_disbursement':
        status = 'Approved for Post Disbursement';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'disqualified':
        status = 'Disqualified';
        style.backgroundColor = '#f9ffb8';
        style.color = '#838c01';
        break;
      default:
        status = 'Pending';
    }

    return (
      <div className="application-status" style={style}>
        {status}
      </div>
    );
  };

  return (
    <>
      {isDesktopOrLaptop && (
        <section>
          <Row className="mx-4 my-4">
            <h2>Welcome, {user?.first_name || user?.user?.first_name}</h2>
          </Row>
          <Row className="mx-4 mt-2">
            <Col xs={12} xl={8}>
              <div className="">
                <h6>Application status</h6>
                <div style={{ marginBottom: '10px' }}>
                  {statusComponent(data?.application_process_status)}
                </div>

                <ApplicationStatusCard data={data} />

                <Box style={{ display: 'flex' }}>
                  {/* <Box style={{ marginRight: '10px' }}>
                    {showGrantFormButton() == true && (
                      <Button
                        onClick={() => {
                          navigate('/grants/personal_information');
                        }}
                        style={{
                          marginTop: '1rem',
                          textTransform: 'initial',
                          fontFamily: 'inherit',
                          backgroundColor: defaultBlue,
                          color: 'white',
                        }}
                      >
                        Start Grant Form
                      </Button>
                    )}
                  </Box> */}

                  {showGrantFormButton() == true && (
                    <Button
                      style={{
                        marginTop: '1rem',
                        textTransform: 'initial',
                        fontFamily: 'inherit',
                        backgroundColor: 'white!important',
                        color: defaultBlue,
                        border: `1px solid ${defaultBlue}`,
                      }}
                      onClick={() => {
                        navigate('/grants/businessplan/guidance');
                      }}
                    >
                      Start Business Plan Form
                    </Button>
                  )}
                </Box>
              </div>
              <div className="mt-5">
                {!is_application_complete ? (
                  <LoanIncompleteCard>
                    <MainButton
                      text="Continue Application"
                      dark={true}
                      link={'/onboarding/personal_information'}
                    />
                  </LoanIncompleteCard>
                ) : null}
              </div>
              <div className="mt-5">
                <h6>Training Programs</h6>
                <Row>
                  <Col xs={12} md={6}>
                    <TrainingProgramCard
                      title="Financial Accounting 101"
                      training_thumbnail="https://images.unsplash.com/photo-1508243529287-e21914733111?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <TrainingProgramCard
                      title="Business Accounting for beginners"
                      training_thumbnail={training_thumb}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <TrainingProgramCard
                      title="Accounting for Agri-Business"
                      training_thumbnail={training_thumb_1}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <TrainingProgramCard title="Inventory" />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col className="d-flex justify-content-start flex-column ms-4 mt-lg-4 mt-xl-none">
              <Row>
                <Col xs={12} md={6} xl={12}>
                  <SeeMoreTitle title="Blog Posts" />
                  <BlogPostCard defaultImage={true} />
                </Col>
                <Col xs={12} md={6} xl={12}>
                  <SeeMoreTitle title="Announcements" />
                  {/* <TrainingPreviewCard /> */}
                  <BlogPostCard defaultImage={false} />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      )}
      {isTabletOrMobile && <MobileDashboard />}
    </>
  );
}
